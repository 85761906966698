import type { InitOptions } from 'i18next'

import enTranslations from '~/locales/en/translation.json'

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'translation'
    returnNull: false
  }
}

export default {
  supportedLngs: ['en'],
  fallbackLng: 'en',
  defaultNS: 'translation',
  react: { useSuspense: false },
  lng: 'en',
  ns: 'translation',
  resources: { en: { translation: enTranslations } },
  returnNull: false,
} satisfies InitOptions
